import React from 'react'
import styles from './jumpbutton.module.scss'

function Jumpbutton({ clickHandler }) {
    return (
        <>
          <button className={styles.btn} onClick={clickHandler}>
            <span className="icon-scroll-down" style={{color:'white'}}></span>
          </button>   
        </>
    )
}

export default Jumpbutton
