import React, { useCallback, useEffect, useRef, useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

// Bilder
import Worldmap from './../images/contact/worldmap.png'
import Worldmap2x from './../images/contact/worldmap@2x.png'
import Numbers from './../images/contact/numbers.svg'
import NumbersEn from './../images/contact/numbers-en.svg'

import { TweenLite } from 'gsap/all'
import { animationType, buildScrollTriggers, createAnimationType, doAnimate, nl2br, resetElements } from '../utils/functions'
import Jumpbutton from '../components/ux/buttons/jumpbutton/jumpbutton'
import { ScrollTrigger } from 'gsap/all'
import GhostButton from '../components/ux/buttons/ghost-button'
import { connect } from 'react-redux'



function Contact({ translations, lang }) {
    const lightHolderRef = useRef();
    const swingerRef = useRef();

    const [rendered, setRendered] = useState(false)

    createAnimationType();

    // Scrollrefs
    const scrollRef1 = useRef();
    const scrollRef2 = useRef();
    const scrollRefs = [
        scrollRef1,
        scrollRef2
    ];

    // Animation Refs
    const h1Ref = useRef();
    const h1Ref2 = useRef();
    const textRef = useRef();
    const imageRef = useRef();
    const imageRef1 = useRef();

    const countries1 = [
        {
            icon: 'germany',
            country: 'Deutschland',
            brand: 'Bodyshake GmbH',
            address1: 'Brauereistraße 45',
            address2: '08064 Zwickau',
            phonenumber: '+49 375 692 451 50',
            mail: 'info@bodyshake.com'
        },
        {
            icon: 'international',
            country: 'International',
            brand: 'Bodyshake GmbH',
            address1: 'Brauereistraße 45',
            address2: '08064 Zwickau, Deutschland',
            address3: '',
            phonenumber: '+49 375 692 451 50',
            mail: 'info@bodyshake.com'
        },
        {
            icon: 'swiss',
            country: 'Schweiz',
            brand: 'Soledor AG',
            address1: 'Grüngenstrasse 19',
            address2: '4416 Bubendorf',
            phonenumber: '+41 61 926 87 77',
            mail: 'info@soledor.ch'
        },
        {
            icon: 'saudi-arabien',
            country: 'Saudi Arabien',
            brand: 'Razz International Trading Co.',
            address1: '6705 King Abdul Aziz rd. Salah Al-Din',
            address2: 'Riyadh 12432 - 2638',
        }
    ]
    const countries2 = [
        {
            icon: 'kuwait',
            country: 'Kuwait',
            brand: 'Razz International Trading Co.',
            address1: '6705 King Abdul Aziz rd. Salah Al-Din',
            address2: 'Riyadh 12432 - 2638',
        },
        {
            icon: 'uae',
            country: 'UAE',
            brand: 'Razz International Trading Co.',
            address1: '6705 King Abdul Aziz rd. Salah Al-Din',
            address2: 'Riyadh 12432 - 2638',
            address3: '',
        },
        {
            icon: 'austria',
            country: 'Österreich',
            brand: 'Bodyshake GmbH',
            address1: 'Brauereistraße 45',
            address2: '08064 Zwickau',
            phonenumber: '+49 375 692 451 50',
            mail: 'info@bodyshake.com'
        },
        {
            icon: 'kroatien',
            country: 'Kroatien',
            brand: 'Franck Snogoo d.o.o.',
            address1: 'Vodovodna 20',
            address2: '10000 Zagreb',
        },
    ]

    const countries3 = [
        {
            icon: 'slowenien',
            country: 'Slowenien',
            brand: 'Franck Snogoo d.o.o.',
            address1: 'Vodovodna 20',
            address2: '10000 Zagreb',
        },
        {
            icon: '',
            country: '',
            brand: '',
            address1: '',
            address2: '',
        },
        {
            icon: '',
            country: '',
            brand: '',
            address1: '',
            address2: '',
        },
        {
            icon: '',
            country: '',
            brand: '',
            address1: '',
            address2: '',
        }
    ]


    // Aktuelle Position, Status etc.
    let currentScrollPos = useRef(1);
    let animationActive = useRef(false);
    let entered = useRef(false);
    let clickable = useRef(true);


    // Animationen für jede Section
    const scrollEffect = useCallback((step) => {
        var durationFactor = 1;
        var delayFactor = .5;
        animationActive.current = true;
        clickable.current = false
        switch (step) {
            case 2:
                // Alte Szene ausblenden
                TweenLite.to(swingerRef.current, { duration: 1.5, xPercent: -50, rotation: 180, autoAlpha: 0, ease: animationType });
                TweenLite.to(h1Ref.current, { duration: durationFactor, x: -50, y: -80, autoAlpha: 0, ease: animationType });
                TweenLite.to('.contact-btn', { duration: durationFactor, x: -50, y: -40, autoAlpha: 0, ease: animationType });
                TweenLite.to(textRef.current, { duration: durationFactor, x: -50, y: -20, autoAlpha: 0, ease: animationType });
                TweenLite.to(imageRef.current, { duration: durationFactor, x: 50, y: -40, autoAlpha: 0, ease: animationType });
                TweenLite.to(imageRef1.current, { duration: durationFactor, x: 50, y: -20, autoAlpha: 0, ease: animationType });

                // Neue Szene einblenden
                TweenLite.from(h1Ref2.current, { duration: durationFactor, delay: delayFactor, y: 50, autoAlpha: 0, ease: animationType });
                TweenLite.from('.ani1', { duration: durationFactor, delay: delayFactor, y: 100, autoAlpha: 0, ease: animationType });
                TweenLite.from('.ani2', { duration: durationFactor, delay: delayFactor, y: 150, autoAlpha: 0, ease: animationType });
                TweenLite.from('.ani3', {
                    duration: durationFactor, delay: delayFactor, y: 200, autoAlpha: 0, ease: animationType, onComplete: () => {
                        animationActive.current = false;
                        clickable.current = true
                        resetElements([h1Ref.current, '.contact-btn', textRef.current, imageRef.current, imageRef1.current]);
                    }
                });

                break;
            default: // Standard Introanimation
                if (entered.current === true) { // Zurückanimation
                    TweenLite.to(h1Ref2.current, { duration: durationFactor, y: 50, autoAlpha: 0, ease: animationType });
                    TweenLite.to('.ani1', { duration: durationFactor, y: 100, autoAlpha: 0, ease: animationType });
                    TweenLite.to('.ani2', { duration: durationFactor, y: 150, autoAlpha: 0, ease: animationType });
                    TweenLite.to('.ani3', {
                        duration: durationFactor, y: 200, autoAlpha: 0, ease: animationType, onComplete: () => {
                            resetElements([h1Ref2.current, '.ani1', '.ani2', '.ani3']); // Nachfolgend
                            clickable.current = true
                        }
                    });
                }
                if (entered.current === true) {
                    TweenLite.to(swingerRef.current, { duration: 1, xPercent: 5, rotation: 90, autoAlpha: 1, ease: animationType });
                } else {
                    TweenLite.from(swingerRef.current, { duration: 1, x: -200, autoAlpha: 0, ease: animationType });
                }
                TweenLite.from(h1Ref.current, { duration: 1, x: -200, y: -50, autoAlpha: 0, ease: animationType });
                TweenLite.from('.contact-btn', { duration: 1, x: -200, y: 75, autoAlpha: 0, ease: animationType });
                TweenLite.from(textRef.current, { duration: 1, x: -200, y: 50, autoAlpha: 0, ease: animationType });
                TweenLite.from(imageRef.current, { duration: 1, x: 200, autoAlpha: 0, ease: animationType });
                TweenLite.from(imageRef1.current, {
                    duration: 1, x: 200, autoAlpha: 0, ease: animationType, onComplete: () => {
                        resetElements([h1Ref2.current, '.ani1', '.ani2', '.ani3']);
                        animationActive.current = false;
                        if (entered.current === false) {
                            clickable.current = true;
                        }
                        entered.current = true;
                    }
                });
                break;
        }
    }, []);

    // Scrollt zur nächsten Section
    const scrollToRef = (ref) => {
        let offset = ref.current.offsetTop + 108;
        if (currentScrollPos.current === 2) { // Bis footer
            offset += 180;
        }
        TweenLite.to(window, { duration: 1.5, scrollTo: offset });

    };

    // Initialisierung
    useEffect(() => {
        if (doAnimate()) {
            document.body.classList.add('noscroll');
            TweenLite.defaultEase = animationType;
            if (rendered !== true) {
                setRendered(true)
                setTimeout(() => {
                    scrollEffect(1);
                    buildScrollTriggers(scrollRefs, scrollEffect, scrollToRef, currentScrollPos, animationActive);
                }, 500);
                setTimeout(() => {
                    document.body.classList.remove('noscroll');
                }, 2000);

            }
            return () => {
                ScrollTrigger.getAll().forEach(t => t.kill());
            }
        }
    }, [scrollEffect, scrollRefs, rendered])

    const clickHandler = () => {
        if (clickable.current === false || !doAnimate()) return
        currentScrollPos.current += 1
        scrollEffect(currentScrollPos.current);
        scrollToRef(scrollRefs[currentScrollPos.current - 1])
    }

    return (
        <div>
            <Layout>
                <SEO title={translations.contact_title} description={translations.contact_meta_description} />
                <div className="contact subpage">
                    <div className="lightholder" ref={lightHolderRef}></div>
                    <div className="swinger animationElement" ref={swingerRef}></div>
                    <section ref={scrollRef1}>
                        <div className="inner-wrapper flex">
                            <div>
                                <div ref={h1Ref} className="animationElement">
                                    <div className='bs-headline'>
                                        {
                                            nl2br(translations.contact_1_white_headline)
                                        }<br />
                                        <span className="yellow">
                                            {
                                                nl2br(translations.contact_1_yellow_headline)
                                            }
                                        </span>
                                    </div>
                                    <p className="t-upper bolder">
                                        {
                                            nl2br(translations.contact_1_subline)
                                        }
                                    </p>
                                </div>
                                <div ref={textRef} className="animationElement">
                                    <p className="light">
                                        {
                                            nl2br(translations.contact_1_description)
                                        }
                                    </p>
                                </div>
                                <GhostButton title={translations.contact_cta} className="contact-btn animationElement" target="#" clickHandler={clickHandler} />
                            </div>
                            <div className="worldinfo mb-2">
                                <img ref={imageRef} className="animationElement" src={Worldmap} srcSet={`${Worldmap} 1x, ${Worldmap2x} 2x`} alt="Bodyshake Worldmap" />
                                {
                                    lang === 'de' ?
                                        <img ref={imageRef1} className="animationElement numbers" src={Numbers} alt="Bodyshake in numbers" />
                                        :
                                        <img ref={imageRef1} className="animationElement numbers" src={NumbersEn} alt="Bodyshake in numbers" />
                                }
                            </div>
                        </div>
                    </section>
                    <section className="bottom" ref={scrollRef2}>
                        <div className="inner-wrapper">
                            <div>
                                <div ref={h1Ref2} className="text-center headline animationElement">
                                    <h1>Bodyshake<br /> <span className="yellow">
                                        {
                                            nl2br(translations.contact_2_distributors)
                                        }
                                    </span></h1>
                                </div>
                            </div>
                            <div className="flex sa ani1 addresses">
                                {
                                    countries1.map((item, index) => (
                                        <div className="distributor" key={index}>
                                            <div className="flex country-sec">
                                                <div className={`${item.icon} country-icon`}></div><span>{item.country}</span>
                                            </div>
                                            <div>
                                                <strong>{item.brand}</strong>
                                            </div>
                                            <div className="description">
                                                {item.address1}<br />{item.address2}

                                            </div>
                                            <div className="link-sec">
                                                <div>
                                                    {item.phonenumber &&
                                                        <a href={`tel:${item.phonenumber}`}>
                                                            <span className="icon-phone"></span> <span>{item.phonenumber}</span>
                                                        </a>
                                                    }
                                                </div>

                                                <div>
                                                    <a href={`mailto:${item.mail}`}>
                                                        <span className="icon-email"></span><span>{item.mail}</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="flex sa ani2 addresses">
                                {
                                    countries2.map((item, index) => (
                                        <div className="distributor" key={index}>
                                            <div className="flex country-sec">
                                                <div className={`${item.icon} country-icon`}></div><span>{item.country}</span>
                                            </div>
                                            <div>
                                                <strong>{item.brand}</strong>
                                            </div>
                                            <div className="description">
                                                {item.address1}<br />{item.address2}

                                            </div>
                                            <div className="link-sec">
                                                <div>
                                                    {item.phonenumber &&
                                                        <a href={`tel:${item.phonenumber}`}>
                                                            <span className="icon-phone"></span> <span>{item.phonenumber}</span>
                                                        </a>
                                                    }
                                                </div>
                                                <div>
                                                    {item.mail &&
                                                        <a href={`mailto:${item.mail}`}>
                                                            <span className="icon-email"></span><span dangerouslySetInnerHTML={{ __html: item.mail }}></span>
                                                        </a>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="flex sa ani3 addresses">
                                {
                                    countries3.map((item, index) => (
                                        <div className={`distributor ${item.icon.length === 0 ? 'desktop' : ''}`} key={index}>
                                            <div className="flex country-sec">
                                                <div className={`${item.icon} country-icon`}></div><span>{item.country}</span>
                                            </div>
                                            <div>
                                                <strong>{item.brand}</strong>
                                            </div>
                                            <div className="description">
                                                {item.address1}<br />{item.address2}

                                            </div>
                                            <div className="link-sec">
                                                <div>
                                                    {item.phonenumber &&
                                                        <a href={`tel:${item.phonenumber}`}>
                                                            <span className="icon-phone"></span> <span>{item.phonenumber}</span>
                                                        </a>
                                                    }
                                                </div>
                                                <div>
                                                    {item.mail &&
                                                        <a href={`mailto:${item.mail}`}>
                                                            <span className="icon-email"></span><span dangerouslySetInnerHTML={{ __html: item.mail }}></span>
                                                        </a>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </section>
                </div>
                <Jumpbutton clickHandler={clickHandler} />
            </Layout>
        </div>
    )
}


const mapStateToProps = state => ({
    translations: state.preferences.translations,
    lang: state.preferences.lang,
    serverCall: state.preferences.serverCall
})

export default connect(mapStateToProps)(Contact)