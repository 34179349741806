import React from 'react'
import styles from './ghost-button.module.scss';
import PropTypes from 'prop-types'
import { Link } from 'gatsby';

function GhostButton({ title, target='', className, clickHandler}) {
    const stl = className ? className : '';
    return (
        target !== '' ? 
        <Link  className={`${styles.ghostbtn} ${stl}`} to={target} onClick={() => {
            if(clickHandler){
                clickHandler();
            }
        }}>
            {title}
        </Link>
        :
        <div className={`${styles.ghostbtn} ${stl}`}>
            { title }
        </div>
    )
}



GhostButton.propTypes = {
    title: PropTypes.string.isRequired
}

export default GhostButton

